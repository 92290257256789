import React, { Component } from 'react';
import axios from 'axios';

import logo from '../images/logo.png';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem('JWT')) {
      this.props.history.push(`/dashboard`);
    }
  }

  onInputChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onSubmit(event) {
    axios.post('/auth/login', {
      email: this.state.email,
      password: this.state.password
    })
      .then(function (response) {
        if (response.data.token) {
          localStorage.setItem('JWT', response.data.token);
          this.props.setLoggedIn();
          this.props.history.push(`/dashboard`);
        } else {
          this.setState({ error: response.data });
        }
      }.bind(this))
      .catch(function (error) {
        this.setState({ error: error.message });
      }.bind(this));
  };

  render() {
    return (
      <div className="bg-light d-flex align-items-center text-center" style={{ height: '100vh' }}>
        {console.log(this.props)}
        <div className="container mb-5" style={{ maxWidth: '350px' }}>
          <div className="navbar-brand mb-4" href="/">
            <img src={logo} height="30" alt="Satismatic"></img>
          </div>
          <div className="bg-white p-4 rounded shadow-sm">
            <h2 className="mb-3 font-weight-light">Login</h2>
            <div className="form-group">
              <input id="email" type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.onInputChange}></input>
            </div>
            <div className="form-group">
              <input id="password" type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.onInputChange}></input>
            </div>
            <button className="btn btn-primary w-100" onClick={this.onSubmit}>Login</button>
            {this.state.error &&
              <div className="alert alert-danger mt-3 mb-0" role="alert">
                {this.state.error}
              </div>
            }
          </div>
          <button className="btn btn-link w-100 mt-3 mb-5">or sign up</button>
        </div>
      </div>
    );
  }

}

export default Login;