import React, { Component } from 'react';
import qs from 'qs';
import axios from 'axios';
import Img from 'react-image';

import Star from './Star';
import Tag from './Tag';
import logo from '../images/logo.png';

class ReviewRequest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      interaction: {},
      agent: {},
      company: {},
      tags: [],
      channel: {},
      rating: null,
      ratingHover: null,
      comment: null,
      selectedTags: [],
      resolution: null,
      commentsTagSubmitted: false,
      resolutionSubmitted: false
    };
    this.onStarClick = this.onStarClick.bind(this);
    this.onStarMouseEnter = this.onStarMouseEnter.bind(this);
    this.onStarMouseLeave = this.onStarMouseLeave.bind(this);
    this.onTagClick = this.onTagClick.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.onCommentTagsSubmit = this.onCommentTagsSubmit.bind(this);
    this.onResolutionSubmit = this.onResolutionSubmit.bind(this);
  }
  // add placeholder images

  componentDidMount() {
    axios.get(`/api/tags`)
      .then(response => { this.setState({ tags: response.data }); })
      .catch(error => { console.log(error); })
    axios.get(`/api/interaction/${this.props.match.params.id}`)
      .then(response => {
        this.setState({
          interaction: response.data.interaction,
          agent: response.data.agent,
          company: response.data.company,
          channel: response.data.channel,
          rating: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).rating ? qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).rating : response.data.interaction.review_rating,
          comment: response.data.interaction.review_comment,
          selectedTags: response.data.tags,
          resolved: response.data.interaction.review_issue_resolved
        });
        axios.put(`/api/interaction/${this.props.match.params.id}`, {
          review_rating: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).rating
        })
          .then(response => { console.log(`Rating changed to ${response.data[0].review_rating} at ${response.data[0].updated_at}`) })
          .catch(error => { console.log(error); })
      })
      .catch(error => { console.log(error); })
  };

  onStarClick(event) {
    let rating = parseInt(event.target.dataset.rating);
    this.setState({ rating: rating });
    axios.put(`/api/interaction/${this.state.interaction.id}`, {
      review_rating: rating
    })
      .then(response => {
        this.props.history.push({ search: `?rating=${rating}` })
      })
      .catch(error => { console.log(error); })
  }

  onStarMouseEnter(event) {
    let rating = parseInt(event.target.dataset.rating);
    this.setState({ ratingHover: rating });
  }

  onStarMouseLeave(event) {
    this.setState({ ratingHover: null });
  }

  onTagClick(event) {
    let clickedTag = event.currentTarget.dataset.id;
    let newTags = this.state.selectedTags
    if (this.state.selectedTags.includes(clickedTag)) {
      newTags = newTags.filter(value => value !== clickedTag)
    } else {
      newTags = newTags.concat(clickedTag);
    }
    this.setState({ selectedTags: newTags });
  }

  onCommentChange(event) {
    this.setState({ comment: event.target.value })
  }

  onCommentTagsSubmit(event) {
    axios.put(`/api/interaction/${this.state.interaction.id}`, {
      review_comment: this.state.comment,
      tags: this.state.selectedTags
    })
      .then(response => { this.setState({ commentsTagSubmitted: true }); })
      .catch(error => { console.log(error); })
  }

  onResolutionSubmit(event) {
    let resolution = event.currentTarget.dataset.resolution;
    this.setState({ resolution: resolution }, () => {
      axios.put(`/api/interaction/${this.state.interaction.id}`, {
        review_issue_resolved: this.state.resolution
      })
        .then(response => { this.setState({ resolutionSubmitted: true }); })
        .catch(error => { console.log(error); });
    });
  };

  render() {
    return (
      <div className="bg-light pt-2 pb-5 text-center">
        <Img className="my-3" width="150" src={this.state.company.logo_url} alt={this.state.company.name} />
        <div className="container email bg-white p-4 rounded shadow-sm">
          {!this.state.commentsTagSubmitted &&
            <div className="mt-2">
              <p>You recently {this.state.channel.verb} with</p>
              <div className="mx-auto p-3 mb-5 rounded" style={{ width: "90%", backgroundColor: "#FAFBFC" }}>
                <Img className="rounded-circle mb-2" width="150" src={this.state.agent.image_url} alt="agent" />
                <h3 style={{ color: this.state.company.brand_color }}>{this.state.agent.first_name}</h3>
                <h5 className="text-secondary">{this.state.agent.title}</h5>
                <p>{this.state.agent.bio}</p>
              </div>
              <h3 className="mb-4">How would you rate the {this.state.channel.noun}?</h3>
              <div id="rating">
                <Star brandColor={this.state.company.brand_color} rating={1} description="Awful" ratingSelected={this.state.rating} ratingHover={this.state.ratingHover} onClick={this.onStarClick} onMouseEnter={this.onStarMouseEnter} onMouseLeave={this.onStarMouseLeave} color={this.state.rating >= 1 ? "#007bff" : "#6c757d"} />
                <Star brandColor={this.state.company.brand_color} rating={2} ratingSelected={this.state.rating} ratingHover={this.state.ratingHover} onClick={this.onStarClick} onMouseEnter={this.onStarMouseEnter} onMouseLeave={this.onStarMouseLeave} color={this.state.rating >= 2 ? "#007bff" : "#6c757d"} />
                <Star brandColor={this.state.company.brand_color} rating={3} ratingSelected={this.state.rating} ratingHover={this.state.ratingHover} onClick={this.onStarClick} onMouseEnter={this.onStarMouseEnter} onMouseLeave={this.onStarMouseLeave} color={this.state.rating >= 3 ? "#007bff" : "#6c757d"} />
                <Star brandColor={this.state.company.brand_color} rating={4} ratingSelected={this.state.rating} ratingHover={this.state.ratingHover} onClick={this.onStarClick} onMouseEnter={this.onStarMouseEnter} onMouseLeave={this.onStarMouseLeave} color={this.state.rating >= 4 ? "#007bff" : "#6c757d"} />
                <Star brandColor={this.state.company.brand_color} rating={5} description="Amazing" ratingSelected={this.state.rating} ratingHover={this.state.ratingHover} onClick={this.onStarClick} onMouseEnter={this.onStarMouseEnter} onMouseLeave={this.onStarMouseLeave} color={this.state.rating >= 5 ? "#007bff" : "#6c757d"} />
              </div>
              {this.state.rating &&
                <textarea className="form-control my-5" id="comment" onChange={this.onCommentChange} rows="3" placeholder="Please tell us why you chose this rating" value={this.state.comment}></textarea>
              }
              {this.state.rating > 0 && this.state.rating < 4 &&
                <div>
                  <h3 className="mb-4">Sorry to hear about your poor experience. Please tell us how we can improve.</h3>
                  <div className="row my-4">
                    {this.state.tags.filter(tag => tag.type === 'negative').map(tag => {
                      return <Tag key={tag.id} {...tag} wordType="noun" onClick={this.onTagClick} selectedTags={this.state.selectedTags} />
                    })}
                  </div>
                </div>
              }
              {this.state.rating >= 4 &&
                <div>
                  <h3 className="mb-4">What did {this.state.agent.first_name} do particularly well?</h3>
                  <div className="row my-4">
                    {this.state.tags.filter(tag => tag.type === 'positive').map(tag => {
                      return <Tag key={tag.id} {...tag} wordType="adjective" onClick={this.onTagClick} selectedTags={this.state.selectedTags} />
                    })}
                  </div>
                </div>
              }
              {this.state.rating &&
                <button onClick={this.onCommentTagsSubmit} className="btn btn-lg btn-primary text-uppercase w-100" style={{ backgroundColor: this.state.company.brand_color, borderColor: this.state.company.brand_color }}>Submit</button>
              }
            </div>
          }
          {this.state.commentsTagSubmitted && !this.state.resolutionSubmitted &&
            <div>
              <h3 className="mb-4">Were we able to resolve your issue?</h3>
              <button data-resolution={true} onClick={this.onResolutionSubmit} className="btn btn-lg btn-primary w-100 mb-3" style={{ backgroundColor: this.state.company.brand_color, borderColor: this.state.company.brand_color }}>Yes, it was resolved</button>
              <button data-resolution={false} onClick={this.onResolutionSubmit} className="btn btn-lg btn-primary w-100" style={{ backgroundColor: this.state.company.brand_color, borderColor: this.state.company.brand_color }}>No, it is still unresolved</button>
            </div>
          }
          {this.state.resolutionSubmitted &&
            <div>
              <h3 className="mb-4">Thanks for your feedback</h3>
              {this.state.rating > 0 && this.state.rating < 4 &&
                <p>We will share your feedback with {this.state.agent.first_name} and do our best to improve.</p>
              }
              {this.state.rating >= 4 &&
                <p>We will share the positive feedback with {this.state.agent.first_name}!</p>
              }
            </div>
          }
        </div>
        <div className="my-5">
          <p className="text-secondary mb-0">Powered by</p>
          <img src={logo} height="20" alt="" />
          <p className="small text-secondary mt-4">© 2019 Satismatic, Inc., all rights reserved</p>
        </div>
      </div>
    );
  }

}

export default ReviewRequest;