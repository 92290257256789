import React, { Component } from 'react';
import axios from 'axios';

class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      current_password: '',
      new_password: '',
      error: null
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onInputChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }


  onSubmit(event) {
    event.preventDefault();
    let accessString = localStorage.getItem('JWT');
    axios.put(`/auth/change-password`, {
      current_password: this.state.current_password,
      new_password: this.state.new_password
    }, { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.props.history.push(`/dashboard`); }) // TODO: Show user it is saved
      .catch(error => { this.setState({ error: error.response.data.message }) });
  };

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          <h1 className="font-weight-light mb-4">Change Password</h1>
          <div className="row">
            <form className="card border-0 shadow-sm col-12 col-md-6" noValidate>
              <div className="card-body">
                <div className="form-group">
                  <label>Current password</label>
                  <input id="current_password" type="password" value={this.state.current_password} onChange={this.onInputChange} className={`form-control${this.state.current_password_error ? ' is-invalid' : ''}`} required />
                  <div className="invalid-feedback">Please enter your current password.</div>
                </div>
                <div className="form-group">
                  <label>New password</label>
                  <input id="new_password" type="password" value={this.state.new_password} onChange={this.onInputChange} className={`form-control${this.state.new_password_error ? ' is-invalid' : ''}`} required />
                  <div className="invalid-feedback">Please enter a valid password.</div>
                </div>
                {this.state.error &&
                  <div className="alert alert-danger" role="alert">{this.state.error}</div>
                }
                <button type="submit" className="btn btn-primary w-100 text-uppercase mt-3" onClick={this.onSubmit}>Change</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

}

export default ChangePassword;