import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Tag extends Component {

  render() {
    return (
      <div data-id={this.props.id} className="col-6 col-md-4 my-3" onClick={this.props.onClick}>
        <div className={`card ${this.props.selectedTags.includes(this.props.id) ? 'bg-dark' : 'bg-secondary'} text-white tag-card`}>
          <div className="card-body">
            <FontAwesomeIcon icon={this.props.icon} size="lg" className="mb-1" />
            <div>{this.props.wordType==="adjective" ? this.props.adjective : this.props.name}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tag;