import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class API extends Component {

  constructor(props) {
    super(props);
    this.state = {
      company: {},
      apiKey: '',
      devApiKey: 'd9e89d792b3d70d585fb0ae374306c80fa60cd5c22632a3f40cf46bc3c835b50'
    };
    this.newApiKey = this.newApiKey.bind(this);
  }

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/company', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.setState({
        company: response.data,
        apiKey: response.data.api_key
      }); })
      .catch(error => { console.log(error); })
  }

  newApiKey() {
    let accessString = localStorage.getItem('JWT');
    axios.put('/api/company/api-key', {}, { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.setState({
        company: response.data,
        apiKey: response.data.api_key
      }); })
      .catch(error => { console.log(error); })
  }

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          <h1 className="font-weight-light">API</h1>
          <p className="mb-4">The Satismatic API allows you to give us your ticket data so that we can start collecting customer feedback on your behalf.</p>
          <form className="card border-0 shadow-sm">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                <h3 className="mb-4">Production</h3>
                  <div className="form-group">
                    <label>API key</label>
                    <input id="name" value={this.state.apiKey} className="form-control" disabled />
                    <div className="invalid-feedback">Please enter a valid name.</div>
                    <small className="form-text text-muted">
                      <FontAwesomeIcon icon="info-circle" className="mr-1" />
                      Keep this key private and do not use in client-side code.
                    </small>
                    <button className="btn btn-outline-danger mt-4" onClick={this.newApiKey}>Generate new API key</button>
                    <small className="form-text text-muted">
                      <FontAwesomeIcon icon="info-circle" className="mr-1" />
                      Generating a new key invalidates the existing key.
                    </small>
                  </div>
                </div>
                <div className="col-6">
                <h3 className="mb-4">Development</h3>
                  <div className="form-group">
                    <label>Dev API key</label>
                    <input id="name" value={this.state.devApiKey} className="form-control" disabled />
                    <div className="invalid-feedback">Please enter a valid name.</div>
                    <small className="form-text text-muted">
                      <FontAwesomeIcon icon="info-circle" className="mr-1" />
                      For testing purposes only.
                    </small>
                  </div>
                </div>
              </div>
            </div> 
          </form>
        </div>
      </div>
    );
  }

}

export default API;