import React, { Component } from 'react';
import axios from 'axios';
import Img from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Navbar from './Navbar';

class Leaderboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      agents: []
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    axios.get(`/api/agents/ratings`, { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.setState({ agents: response.data }); })
      .catch(error => { console.log(error); })
  }

  onChange(event) {
    event.preventDefault();
    console.log(event.target);
    this.setState({ description: event.target.id });
  }

  render() {
    return (
      <div>
        <Navbar user={this.props.user} containerFluid={true} />
        <div id="board" className="container-fluid" style={{ height: '100vh' }}>
          {this.state.agents
            .filter(agent => agent.average_ratings)
            .filter(agent => agent.count_ratings >= 2)
            .sort((a, b) => b.average_ratings - a.average_ratings)
            .map((agent, index) => {
              return <div key={agent.email} className="row bg-primary text-white px-4 d-flex align-items-center" style={{ height: '16.666vh', background: 'linear-gradient(to bottom, #007bff 0%,#1485ff 99%)' }}>
                <div className="col-4 text-center">
                  <Img src={agent.image_url} className="rounded-circle" style={{height: '10vh'}} alt={agent.first_name} />
                </div>
                <div className="col-4">
                  <h2>{index + 1}. {agent.first_name} {agent.last_name}</h2>
                </div>
                <div className="col-4 text-center">
                  <h2><FontAwesomeIcon icon="star" className="small mr-2" />{(Math.round(agent.average_ratings * 100) / 100).toFixed(2)}</h2>
                </div>
              </div>
            })}
        </div>
      </div>
    );
  }

}

export default Leaderboard;