import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Img from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    axios.get(`/api/agents`, { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.setState({ users: response.data }); })
      .catch(error => { console.log(error); })
  }

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          <Link to={`/dashboard/create-user`} className="btn btn-primary float-right"><FontAwesomeIcon icon="user-plus" className="mr-1" />Add user</Link>
          <h1 className="font-weight-light mb-4">Users</h1>
          <form className="card border-0 shadow-sm">
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{borderBottomWidth: '1px'}}>Name</th>
                    <th scope="col" style={{borderBottomWidth: '1px'}}>Email</th>
                    <th scope="col" style={{borderBottomWidth: '1px'}}>Title</th>
                    <th scope="col" style={{borderBottomWidth: '1px'}}>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map(user => {
                    return <tr key={user.id}>
                      <td><Img src={user.image_url} className="rounded-circle mr-2" height="20" alt={user.first_name}></Img>{user.first_name} {user.last_name}</td>
                      <td>{user.email}</td>
                      <td>{user.title}</td>
                      <td>
                        <Link to={`/dashboard/edit-user/${user.id}`} className="text-primary mr-3"><FontAwesomeIcon icon="edit" /></Link>
                        {/* <Link to={`/dashboard/delete-user/${user.id}`} className="text-danger"><FontAwesomeIcon icon="trash" /></Link> */}
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    );
  }

}

export default Users;