import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import Navbar from './Navbar';
import Footer from './Footer';
import Main from './Main';
import EmailFunnel from './EmailFunnel';
import UserSettings from './UserSettings';
import CompanySettings from './CompanySettings';
import Users from './Users';
import ChangePassword from './ChangePassword';
import Trends from './Trends';
import Log from './Log';
import API from './API';
import Integrations from './Integrations';
import Example from './Example';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Navbar user={this.props.user} containerFluid={false} />
        <Route path={this.props.match.path} exact render={(props) => <Main {...props} user={this.props.user} />} />
        <Route path={`${this.props.match.url}/email-funnel`} component={EmailFunnel} />
        <Route path={`${this.props.match.url}/edit-user/:id`} render={(props) => <UserSettings {...props} user={this.props.user} mode="edit" />} />
        <Route path={`${this.props.match.url}/create-user`} render={(props) => <UserSettings {...props} user={this.props.user} mode="create" />} />
        <Route path={`${this.props.match.url}/company-settings`} component={CompanySettings} />
        <Route path={`${this.props.match.url}/users`} component={Users} />
        <Route path={`${this.props.match.url}/change-password`} render={(props) => <ChangePassword {...props} user={this.props.user} />} />
        <Route path={`${this.props.match.url}/trends`} render={(props) => <Trends {...props} user={this.props.user} />} />
        <Route path={`${this.props.match.url}/log`} component={Log} />
        <Route path={`${this.props.match.url}/api`} component={API} />
        <Route path={`${this.props.match.url}/integrations`} component={Integrations} />
        <Route path={`${this.props.match.url}/example`} component={Example} />
        <Footer />
        {this.props.loggedIn ||
          <Redirect to='/login' />
        }
      </div>
    );
  }

}

export default Dashboard;