import React, { Component } from 'react';
import axios from 'axios';
import Img from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UserSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      agent: {
        // id: '',
        company_id: '',
        email: '',
        first_name: '',
        last_name: '',
        role: '',
        title: '',
        bio: '',
        image_url: '',
      },
      agentErrors: {
        email: false,
        first_name: false,
        last_name: false,
        role: false,
        title: false,
        bio: false,
        image_url: false,
      },
      deleteActive: false,
      deleteCheck: false,
      saved: false
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onUploadButtonClick = this.onUploadButtonClick.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteCheck = this.onDeleteCheck.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  updateUserInfo() {
    if (this.props.mode === 'edit') {
      let agentId = this.props.match.params.id;
      let accessString = localStorage.getItem('JWT');
      axios.get(`/api/agent/${agentId}`, { headers: { Authorization: `JWT ${accessString}` } })
        .then(response => { this.setState({ agent: response.data }); })
        .catch(error => { console.log(error); })
    } else if (this.props.mode === 'create') {
      let newAgent = this.state.agent;
      newAgent.company_id = this.props.user.company_id;
      this.setState({ agent: newAgent });
    }
  }

  componentDidMount() {
    this.updateUserInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.updateUserInfo();
    }
  }

  onInputChange(event) {
    let newAgent = this.state.agent;
    newAgent[event.target.id] = event.target.value;
    this.setState({ agent: newAgent });
  }

  onUploadButtonClick(event) {
    event.preventDefault();
    var uploadWidget = window.cloudinary.createUploadWidget({
      cloudName: "satismatic",
      uploadPreset: "profile_picture",
      sources: [
        "url",
        "dropbox",
        "local"
      ],
      showAdvancedOptions: false,
      cropping: true,
      multiple: false,
      defaultSource: "local",
      styles: {
        palette: {
          window: "#ffffff",
          sourceBg: "#F8F9FA",
          windowBorder: "#90a0b3",
          tabIcon: "#000000",
          inactiveTabIcon: "#555a5f",
          menuIcons: "#555a5f",
          link: "#007BFF",
          action: "#28A745",
          inProgress: "#007BFF",
          complete: "#28A745",
          error: "#DC3545",
          textDark: "#000000",
          textLight: "#fcfffd"
        },
        fonts: {
          default: null,
          "sans-serif": {
            url: null,
            active: true
          }
        }
      }
    }, (error, result) => {
      if (result && result.event === 'success') {
        uploadWidget.close();
        let imageUrl = result.info.url;
        let newAgent = this.state.agent;
        newAgent.image_url = imageUrl;
        this.setState({ company: newAgent });
      }
    })
    uploadWidget.open();
  }

  validate() {
    return {
      email: !this.state.agent.email || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.agent.email),
      first_name: !this.state.agent.first_name || this.state.agent.first_name.length === 0,
      last_name: !this.state.agent.last_name || this.state.agent.last_name.length === 0,
      role: !this.state.agent.role || this.state.agent.role.length === 0,
      title: !this.state.agent.title || this.state.agent.title.length === 0,
      bio: !this.state.agent.bio || this.state.agent.bio.length === 0,
      image_url: !this.state.agent.image_url || this.state.agent.image_url.length === 0,
    }
  }

  onSave(event) {
    event.preventDefault();
    this.setState({
      agentErrors: this.validate()
    }, () => {
      let errorsPresent = Object.keys(this.state.agentErrors).some(value => this.state.agentErrors[value]);
      if (!errorsPresent) {
        let method = this.props.mode === 'edit' ? 'put' : 'post';
        let accessString = localStorage.getItem('JWT');
        axios[method](`/api/agent/${this.state.agent.id || ''}`, this.state.agent, { headers: { Authorization: `JWT ${accessString}` } })
          .then(response => {
            this.setState({ saved: true });
            setTimeout( () => { this.props.history.push(`/dashboard/users`); }, 1000);
          })
          .catch(error => { console.log(error); });
      };
    });
  };

  onDeleteClick() {
    this.setState({ deleteActive: true });
  };

  onDeleteCancel() {
    this.setState({
      deleteActive: false,
      deleteCheck: false
    });
  };

  onDeleteCheck() {
    this.setState({ deleteCheck: !this.state.deleteCheck });
  };

  onDeleteConfirm() {
    if (this.state.deleteCheck) {
      let agentId = this.props.match.params.id;
      let accessString = localStorage.getItem('JWT');
      axios.delete(`/api/agent/${agentId}`, { headers: { Authorization: `JWT ${accessString}` } })
        .then(response => {
          this.props.history.push(`/dashboard/users`);
          // TODO: Show user it is saved
        })
        .catch(error => { console.log(error); });
    };
  };

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          {this.props.mode === 'edit' && !this.state.deleteActive &&
            <button className="btn btn-outline-danger float-right" onClick={this.onDeleteClick}><FontAwesomeIcon icon="trash" className="mr-1" />Delete user</button>
          }
          {this.props.mode === 'edit' && this.state.deleteActive &&
            <div>
              <button className="btn btn-secondary float-right ml-2" onClick={this.onDeleteCancel}><FontAwesomeIcon icon="times" className="mr-1" />Cancel</button>
              <button className="btn btn-danger float-right ml-3" onClick={this.onDeleteConfirm}><FontAwesomeIcon icon="trash" className="mr-1" />Delete user (cannot be reversed)</button>
              <div className="custom-control custom-checkbox float-right mt-2">
                <input type="checkbox" className="custom-control-input" id="delete-check" onChange={this.onDeleteCheck} />
                <label className="custom-control-label" htmlFor="delete-check">Check to confirm deletion of {this.state.agent.first_name}</label>
              </div>
            </div>
          }
          <h1 className="font-weight-light mb-4">{this.props.mode === 'edit' ? 'Edit User' : 'Create User'}</h1>
          <form className="card border-0 shadow-sm" noValidate>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>First name</label>
                        <input id="first_name" value={this.state.agent.first_name} onChange={this.onInputChange} className={`form-control${this.state.agentErrors.first_name ? ' is-invalid' : ''}`} required />
                        <div className="invalid-feedback">Please enter a valid name.</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Last name</label>
                        <input id="last_name" value={this.state.agent.last_name} onChange={this.onInputChange} className={`form-control${this.state.agentErrors.last_name ? ' is-invalid' : ''}`} required />
                        <div className="invalid-feedback">Please enter a valid name.</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" id="email" value={this.state.agent.email} onChange={this.onInputChange} className={`form-control${this.state.agentErrors.email ? ' is-invalid' : ''}`} required />
                    <div className="invalid-feedback">Please enter a valid email address.</div>
                  </div>
                  <div className="form-group">
                    <label>Role</label>
                    <select id="role" value={this.state.agent.role} onChange={this.onInputChange} className={`form-control${this.state.agentErrors.email ? ' is-invalid' : ''}`} required>
                      <option value=""></option>
                      <option value="agent">Agent</option>
                      <option value="admin">Admin</option>
                    </select>
                    <div className="invalid-feedback">Please select a role from the list.</div>
                  </div>
                  <div className="form-group">
                    <label>Title</label>
                    <input id="title" value={this.state.agent.title} onChange={this.onInputChange} className={`form-control${this.state.agentErrors.title ? ' is-invalid' : ''}`} required />
                    <div className="invalid-feedback">Please enter a valid title.</div>
                  </div>
                  <div className="form-group">
                    <label>Bio</label>
                    <textarea id="bio" value={this.state.agent.bio} onChange={this.onInputChange} className={`form-control${this.state.agentErrors.bio ? ' is-invalid' : ''}`} rows="3" required />
                    <div className="invalid-feedback">Please enter a valid bio.</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Profile image</label>
                    <input hidden id="image_url" value={this.state.agent.image_url} onChange={this.onInputChange} className={`form-control${this.state.agentErrors.image_url ? ' is-invalid' : ''}`} required />
                    <button className="btn btn-primary d-block" onClick={this.onUploadButtonClick}>Upload image</button>
                    <div className="invalid-feedback">Please upload an image.</div>
                  </div>
                  <Img className="img-fluid rounded mb-3" width="194" src={this.state.agent.image_url} alt={this.state.agent.first_name} />
                </div>
              </div>
              <button type="submit" className="btn btn-lg btn-primary w-100 text-uppercase" onClick={this.onSave}>Save</button>
              {this.state.saved &&
                <div className="alert alert-success text-center mt-3 mb-0" role="alert">User settings saved successfully</div>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }

}

export default UserSettings;