import React, { Component } from 'react';
import axios from 'axios';

class Template extends Component {

  constructor(props) {
    super(props);
    this.state = {
      response: '',
      post: '',
      responseToPost: '',
      test: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.callApi()
      .then(res => this.setState({ response: res.message }))
      .catch(err => console.log(err));
  }

  callApi = async () => {
    const response = await fetch('/api/hello');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  handleSubmit = async e => {
    e.preventDefault();
    let post = this.state.post;
    let query = `query hello ($post: String) { hello (msg: $post) }`;
    const response = await fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: { post }
      })
    });
    const body = await response.text();
    this.setState({ responseToPost: body })
  }

  onClick() {
    let accessString = localStorage.getItem('JWT');
    console.log(accessString);
    axios.get('/auth/hello', {
      params: {
        email: 'matt@snappr.co'
      },
      headers: { Authorization: `JWT ${accessString}`}
    })
    .then(response => {
      console.log(response);
      this.setState({
        test: response.data.message
      })
    })

  }

  render() {
    return (
      <div>
        <p>{this.state.response}</p>
        <form onSubmit={this.handleSubmit}>
          <p>Post to server:</p>
          <input
            type="text"
            value={this.state.post}
            onChange={e => this.setState({ post: e.target.value })}
          />
          <button type="submit">Submit</button>
        </form>
        <p>{this.state.responseToPost}</p>
        <button className="btn btn-primary" onClick={this.onClick}>Click Me</button>
        <h1>{this.state.test}</h1>
      </div>
    );
  }

}

export default Template;