import React, { Component } from 'react';

class Star extends Component {

  render() {

    const color = this.props.ratingSelected >= this.props.rating ? this.props.brandColor : "#6c757d";

    return (
      <div className="d-inline-block align-top" style={{ width: "17%", fontSize: "10px", textTransform: "uppercase" }}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.481 22.481" xlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 19.481 19.481">
          <g>
            <filter id="dropshadow" height="130%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
              <feOffset dx="0.1" dy="0.1" result="offsetblur" />
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.5" />
              </feComponentTransfer>
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            <path
              data-rating={this.props.rating}
              onClick={this.props.onClick}
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              style={{ fill: color, filter: this.props.ratingHover >= this.props.rating ? "url(#dropshadow)" : "" }}
              d="m10.201,.758l2.478,5.865 6.344,.545c0.44,0.038 0.619,0.587 0.285,0.876l-4.812,4.169 1.442,6.202c0.1,0.431-0.367,0.77-0.745,0.541l-5.452-3.288-5.452,3.288c-0.379,0.228-0.845-0.111-0.745-0.541l1.442-6.202-4.813-4.17c-0.334-0.289-0.156-0.838 0.285-0.876l6.344-.545 2.478-5.864c0.172-0.408 0.749-0.408 0.921,0z"
              transform="translate(1.5, 1.5)"
            />
          </g>
        </svg>
        <div>{this.props.description}</div>
      </div>
    )
  }

}

export default Star;