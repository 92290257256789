import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import axios from 'axios';

import logo from '../images/logo.png';

class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userDropdownShow: false,
      companyDropdownShow: false,
      error: null
    };
    this.onUserClick = this.onUserClick.bind(this);
    this.onCompanyClick = this.onCompanyClick.bind(this);
    this.onLogout = this.onLogout.bind(this);
  };

  onUserClick(event) {
    this.setState({ userDropdownShow: !this.state.userDropdownShow });
  };

  onCompanyClick(event) {
    this.setState({ companyDropdownShow: !this.state.companyDropdownShow });
  };

  onLogout() {
    localStorage.removeItem('JWT');
    axios.get('/auth/logout')
      .then(response => { this.props.history.push(`/login`); })
      .catch(error => { this.setState({ error: error.message }); });
  };

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
        <div className={this.props.containerFluid ? 'container-fluid' : 'container'}>
          <a className="navbar-brand" href="/">
            <img src={logo} height="25" className="d-inline-block align-top mr-1" alt="Satismatic"></img>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <li className="nav-item active">
                <Link to="/dashboard" className="nav-link">Feed</Link>
              </li>
              <li className="nav-item">
                <Link to="/dashboard/trends" className="nav-link">Trends</Link>
              </li>
              <li className="nav-item">
                <Link to="/dashboard/email-funnel" className="nav-link">Email funnel</Link>
              </li>
              <li className="nav-item">
                <Link to="/dashboard/log" className="nav-link">Log</Link>
              </li>
              <li className="nav-item">
                <Link to="/leaderboard#board" className="nav-link">Leaderboard</Link>
              </li>
            </div>
            <div className="navbar-nav ml-auto">
              <li className="nav-item dropdown" onClick={this.onUserClick} >
                <span className="nav-link dropdown-toggle">
                  <img src={this.props.user.image_url || ''} className="rounded-circle mr-1" height="20" alt={this.props.user.first_name || ''}></img>
                  {this.props.user.email || ''}
                </span>
                <div className={`dropdown-menu${this.state.userDropdownShow ? ' show' : ''}`}>
                  <Link className="dropdown-item" to={`/dashboard/edit-user/${this.props.user.id || ''}`}>User settings</Link>
                  <Link className="dropdown-item" to={`/dashboard/change-password`}>Change password</Link>
                  <span className="dropdown-item" onClick={this.onLogout}>Logout</span>
                </div>
              </li>
              {this.props.user.role === 'admin' &&
                <li className="nav-item dropdown" onClick={this.onCompanyClick}>
                  <span className="nav-link dropdown-toggle">
                    <img src={this.props.user.company.logo_url || ''} className="mr-1" height="20" alt=""></img>
                    Snappr
                </span>
                  <div className={`dropdown-menu${this.state.companyDropdownShow ? ' show' : ''}`}>
                    <Link className="dropdown-item" to="/dashboard/company-settings">Company settings</Link>
                    <Link className="dropdown-item" to="/dashboard/users">Users</Link>
                    <Link className="dropdown-item" to="/dashboard/integrations">Integrations</Link>
                    <Link className="dropdown-item" to="/dashboard/api">API</Link>
                    <Link className="dropdown-item disabled" to="/dashboard/api">Billing</Link>
                  </div>
                </li>
              }
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);