import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const quotes = [
  {
    quote: 'Assumptions are the termites of relationships.',
    source: 'Henry Winkler',
    sourceTitle: 'American Actor'
  },
  {
    quote: 'There is only one boss. The customer. And he can fire everybody...',
    source: 'Sam Walton',
    sourceTitle: 'Founder, Walmart'
  },
  {
    quote: 'Just having satisfied customers isn’t good enough anymore. If you really want a booming business, you have to create raving fans.',
    source: 'Ken Blanchard',
    sourceTitle: 'American Author'
  },
  {
    quote: 'Your most unhappy customers are your greatest source of learning.',
    source: 'Bill Gates',
    sourceTitle: 'Founder, Microsoft'
  },
  {
    quote: 'People do not care how much you know until they know how much you care.',
    source: 'Teddy Roosevelt',
    sourceTitle: 'American President'
  },
  {
    quote: 'People will forget what you said. They will forget what you did. But they will never forget how you made them feel.',
    source: 'Maya Angelou',
    sourceTitle: 'American Poet'
  },
];

class FeedLoader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      quote: quotes[Math.floor(Math.random() * quotes.length)]
    };
  }

  render() {
    return (
      <div className="text-center text-secondary m-5">
        <FontAwesomeIcon icon="spinner" size="lg" spin className="mb-3" />
        <h2 className="font-weight-light font-italic">
          "{this.state.quote.quote}"
        </h2>
        <p className="font-weight-bold mt-3 mb-0">
          {this.state.quote.source}
        </p>
        <p className="small text-uppercase">
          {this.state.quote.sourceTitle}
        </p>
      </div>
    );
  }

}

export default FeedLoader;