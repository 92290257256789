import React, { Component } from 'react';
import logo from '../images/logo.png';

class Footer extends Component {

  render() {
    return (
      <div className="bg-white py-4">
        <div className="container text-center">
          <a className="navbar-brand" href="/">
            <img src={logo} height="20" alt="" />
          </a>
          <div className="small text-secondary">© 2019 Satismatic, Inc., all rights reserved.</div>
        </div>
      </div>
    );
  }
}

export default Footer;