import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import axios from 'axios';

class EmailFunnel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      data: []
    };
    this.getChart = this.getChart.bind(this);
  }

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/email-events/aggregate', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => {
        let sortedResponse = response.data.sort((a, b) => b.count - a.count);
        this.setState({
          labels: sortedResponse.map(event => event.event.charAt(0).toUpperCase() + event.event.substr(1).toLowerCase()),
          data: sortedResponse.map(event => event.count)
        })
      });
  };

  getChart() {
    return {
      labels: this.state.labels,
      datasets: [{
        label: 'Count of events',
        data: this.state.data,
        backgroundColor: '#007bff',
      }],
    }
  }

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          <h1 className="font-weight-light mb-4">Email Funnel</h1>
          <form className="card border-0 shadow-sm">
            <div className="card-body">
              <HorizontalBar
                data={this.getChart}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    xAxes: [{
                      display: true,
                      ticks: {
                        beginAtZero: true,
                        stepSize: 1
                      }
                    }],
                    yAxes: [{
                      gridLines: {
                        display: false
                      }
                    }]
                  }
                }}
              />
            </div>
          </form>
        </div>
      </div >
    );
  }

}

export default EmailFunnel;