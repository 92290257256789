import React, { Component } from 'react';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import Img from 'react-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CompanySettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      company: {
        id: '',
        name: '',
        slug: '',
        domain: '',
        reply_email: '',
        logo_url: '',
        brand_color: '',
        suppression_period: '',
      },
      companyErrors: {
        id: false,
        name: false,
        slug: false,
        domain: false,
        reply_email: false,
        logo_url: false,
        brand_color: false,
        suppression_period: false,
      },
      displayColorPicker: false,
      saved: false
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onColorPickerClick = this.onColorPickerClick.bind(this);
    this.onColorPickerClose = this.onColorPickerClose.bind(this);
    this.onColorPickerChange = this.onColorPickerChange.bind(this);
    this.onUploadButtonClick = this.onUploadButtonClick.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/company', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.setState({ company: response.data }); })
      .catch(error => { console.log(error); })
  }

  onInputChange(event) {
    let newCompany = this.state.company;
    newCompany[event.target.id] = event.target.value;
    this.setState({ company: newCompany });
  }

  onColorPickerClick(event) {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  onColorPickerClose(event) {
    this.setState({ displayColorPicker: false });
  }

  onColorPickerChange(color) {
    let newCompany = this.state.company;
    newCompany.brand_color = color.hex;
    this.setState({ company: newCompany });
  }

  onUploadButtonClick(event) {
    event.preventDefault();
    var uploadWidget = window.cloudinary.createUploadWidget({
      cloudName: "satismatic",
      uploadPreset: "company_logo",
      sources: [
        "url",
        "dropbox",
        "local"
      ],
      showAdvancedOptions: false,
      cropping: true,
      multiple: false,
      defaultSource: "local",
      styles: {
        palette: {
          window: "#ffffff",
          sourceBg: "#F8F9FA",
          windowBorder: "#90a0b3",
          tabIcon: "#000000",
          inactiveTabIcon: "#555a5f",
          menuIcons: "#555a5f",
          link: "#007BFF",
          action: "#28A745",
          inProgress: "#007BFF",
          complete: "#28A745",
          error: "#DC3545",
          textDark: "#000000",
          textLight: "#fcfffd"
        },
        fonts: {
          default: null,
          "sans-serif": {
            url: null,
            active: true
          }
        }
      }
    }, (error, result) => {
      console.log(error, result);
      if (result && result.event === 'success') {
        uploadWidget.close();
        let logoUrl = result.info.url;
        let newCompany = this.state.company;
        newCompany.logo_url = logoUrl;
        this.setState({ company: newCompany });
      }
    })
    uploadWidget.open();
  }

  validate() {
    return {
      name: this.state.company.name.length === 0,
      reply_email: !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.company.reply_email),
      logo_url: this.state.company.logo_url.length === 0,
      suppression_period: !Number.isInteger(parseFloat(this.state.company.suppression_period)),
    }
  }

  onSave(event) {
    event.preventDefault();
    this.setState({
      companyErrors: this.validate()
    }, () => {
      let errorsPresent = Object.keys(this.state.companyErrors).some(value => this.state.companyErrors[value]);
      if (!errorsPresent) {
        let accessString = localStorage.getItem('JWT');
        axios.put('/api/company', this.state.company, { headers: { Authorization: `JWT ${accessString}` } })
          .then(response => { this.setState({ saved: true }) })
          .catch(error => { console.log(error); })
      }
    })
  }

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          <h1 className="font-weight-light mb-4">Company Settings</h1>
          <form className="card border-0 shadow-sm">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Company name</label>
                    <input id="name" value={this.state.company.name} onChange={this.onInputChange} className={`form-control${this.state.companyErrors.name ? ' is-invalid' : ''}`} />
                    <div className="invalid-feedback">Please enter a valid name.</div>
                    <small className="form-text text-muted">
                      <FontAwesomeIcon icon="info-circle" className="mr-1" />
                      This is how your company name will be shown in emails to customers.
                  </small>
                  </div>
                  <div className="form-group">
                    <label>Reply to email</label>
                    <input id="reply_email" value={this.state.company.reply_email} onChange={this.onInputChange} className={`form-control${this.state.companyErrors.reply_email ? ' is-invalid' : ''}`} />
                    <div className="invalid-feedback">Please enter a valid email address.</div>
                    <small className="form-text text-muted">
                      <FontAwesomeIcon icon="info-circle" className="mr-1" />
                      Any customer responses to review request emails will go to this address.
                  </small>
                  </div>
                  <div className="form-group">
                    <label>Email suppression period (in days)</label>
                    <input id="suppression_period" value={this.state.company.suppression_period} onChange={this.onInputChange} className={`form-control${this.state.companyErrors.suppression_period ? ' is-invalid' : ''}`} />
                    <div className="invalid-feedback">Please enter an integer.</div>
                    <small className="form-text text-muted">
                      <FontAwesomeIcon icon="info-circle" className="mr-1" />
                      If a customer has already received a review request within the period, they will receive no further requests.
                  </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Company logo</label>
                    <input hidden id="image_url" value={this.state.company.logo_url} onChange={this.onInputChange} className={`form-control${this.state.companyErrors.logo_url ? ' is-invalid' : ''}`} required />
                    <button className="btn btn-primary d-block" onClick={this.onUploadButtonClick}>Upload image</button>
                    <div className="invalid-feedback">Please upload an image.</div>
                  </div>
                  <Img className="img-fluid rounded mb-3" width="155" src={this.state.company.logo_url} alt="Logo" />
                  <div className="form-group">
                    <label>Brand color</label>
                    <div className="input-group">
                      <input className="form-control" onClick={this.onColorPickerClick} readOnly value={this.state.company.brand_color} />
                      <div className="input-group-append">
                        <span className="input-group-text" style={{ width: "40px", backgroundColor: this.state.company.brand_color }}></span>
                      </div>
                    </div>
                    {this.state.displayColorPicker &&
                      <div style={{ position: 'absolute', zIndex: '999' }}>
                        <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }} onClick={this.onColorPickerClose}></div>
                        <SketchPicker
                          color={this.state.company.brand_color}
                          onChangeComplete={this.onColorPickerChange}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
              <button className="btn btn-lg btn-primary w-100 text-uppercase" onClick={this.onSave}>Save</button>
              {this.state.saved &&
                <div className="alert alert-success text-center mt-3 mb-0" role="alert">Company settings saved successfully</div>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }

}

export default CompanySettings;