import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Integrations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      company: {},
      slackMessage: '',
      slackMessageSuccess: null,
      slackMessageError: null,
      slackRevokeError: null
    };
    this.getCompany = this.getCompany.bind(this);
    this.onSlackMessageChange = this.onSlackMessageChange.bind(this);
    this.onSlackMessageSubmit = this.onSlackMessageSubmit.bind(this);
    this.onSlackRevoke = this.onSlackRevoke.bind(this);
  }

  componentDidMount() {
    this.getCompany();
  }

  getCompany() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/company', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.setState({ company: response.data }); })
      .catch(error => { console.log(error); })
  }

  onSlackMessageChange(event) {
    this.setState({ slackMessage: event.target.value })
  }

  onSlackMessageSubmit() {
    let accessString = localStorage.getItem('JWT');
    axios.post('/api/send-slack-message',
      { text: this.state.slackMessage },
      { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.getCompany(); })
      .catch(error => {
        this.setState({ slackMessageSuccess: false, slackMessageError: error.message });
      })
  }

  onSlackRevoke() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/auth/slack/revoke', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.getCompany(); })
      .catch(error => { this.setState({ slackRevokeError: error.message }); })
  }

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          <h1 className="font-weight-light mb-4">Integrations</h1>
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <h5><FontAwesomeIcon icon={['fab', 'slack']} className="mr-2" onClick={this.props.refreshFeed} />Slack</h5>
              {this.state.company.slack_credentials ?
                <div>
                  <p>Satismatic has been connected to the {this.state.company.slack_credentials.team_name} Team Slack. Currently posting in the <strong>{this.state.company.slack_credentials.incoming_webhook.channel}</strong> channel.</p>
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Enter a test message..." onChange={this.onSlackMessageChange} />
                    <div className="input-group-append">
                      <button className="btn btn-primary" onClick={this.onSlackMessageSubmit}>Test the connection</button>
                    </div>
                  </div>
                  {this.state.slackMessageSuccess &&
                    <div class="alert alert-success">Your message has sent successfully - go check your Slack and see if it arrived!</div>
                  }
                  {this.state.slackMessageSuccess === false &&
                    <div class="alert alert-danger">There was an error sending your message: {this.state.slackMessageError}</div>
                  }
                   {this.state.slackRevokeError &&
                    <div class="alert alert-danger">There was an error revoking your Slack connection: {this.state.slackMessageError}</div>
                  }
                  <button className="btn btn-link text-danger" onClick={this.onSlackRevoke}>Remove Slack integration</button>
                </div>
                :
                <div>
                  <p>Slack is not yet integrated.</p>
                  <a href="https://slack.com/oauth/authorize?client_id=522291080435.522395522706&scope=incoming-webhook&redirect-uri" className="btn btn-primary"><FontAwesomeIcon icon={['fab', 'slack']} className="mr-2" />Add to Slack</a>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Integrations;