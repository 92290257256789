import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import Summary from './Summary';
import Filters from './Filters';
import Feed from './Feed';

let initialState = {
  feedItems: [],
  users: [],
  filterRating: [null, 1, 2, 3, 4, 5],
  filterChannel: [null, 'email', 'phone', 'chat'],
  filterResolution: [null, 'true', 'false'],
  filterAgent: [null],
  filterType: [null],
  filterSearch: '',
  startDate: null,
  endDate: null,
  responseRate: 0,
  loading: true,
  error: null
};

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.refreshFeed = this.refreshFeed.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFilterClear = this.onFilterClear.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  };

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    this.refreshFeed();
    axios.get('/api/interactions/aggregate', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => this.setState({
        responseRate: Math.round(response.data[0].count_ratings / response.data[0].count_interactions * 100) / 100, // REPLACE WITH MATHS
      }))
      .catch(error => { console.log(error); })
    axios.get('/api/agents', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => {
        initialState.filterAgent = response.data.map(user => user.email);
        this.setState({
          users: response.data,
          filterAgent: response.data.map(user => user.email)
        });
      })
      .catch(error => { console.log(error); })
  };

  refreshFeed(event) {
    this.setState({ loading: true });
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/interactions?reviewed=true', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => {
        let types = response.data.map(item => item.ticket_type).filter((item, index, self) => self.indexOf(item) === index);
        initialState.filterType = [null].concat(types);
        this.setState({
          loading: false,
          feedItems: response.data,
          types: types,
          filterType: [null].concat(types)
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error.message
        });
      })
  }

  onFilterChange(event) {
    console.log(this.state.filterType);
    let clickedCategory = event.target.dataset.category;
    let clickedValue = clickedCategory === 'Rating' ? parseInt(event.target.dataset.filter) : event.target.dataset.filter;
    let updatedFilterList = this.state['filter' + clickedCategory].length === initialState['filter' + clickedCategory].length ? [] : this.state['filter' + clickedCategory];
    if (event.target.checked) {
      updatedFilterList.push(clickedValue);
    } else {
      updatedFilterList = updatedFilterList.length === 1 ? initialState['filter' + clickedCategory] : updatedFilterList.filter(value => value !== clickedValue);
    }
    this.setState({ ['filter' + clickedCategory]: updatedFilterList });
  };

  onFilterClear(event) {
    document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
    this.setState({
      filterRating: initialState.filterRating,
      filterChannel: initialState.filterChannel,
      filterResolution: initialState.filterResolution,
      filterAgent: initialState.filterAgent,
      filterType: initialState.filterType,
      filterSearch: initialState.filterSearch,
      startDate: null,
      endDate: null
    });
  };

  onSearchChange(event) {
    event.preventDefault();
    let newSearch = event.target.value;
    this.setState({ filterSearch: newSearch });
  };

  onDateChange({ startDate, endDate }) {
    this.setState({ startDate, endDate })
  };

  render() {

    let filteredFeed = this.state.feedItems.filter(item => {
      return this.state.filterRating.includes(item.review_rating)
        && this.state.filterChannel.includes(item.channel)
        && this.state.filterResolution.includes(item.review_issue_resolved ? item.review_issue_resolved.toString() : null)
        && this.state.filterAgent.includes(item.agent_email)
        && this.state.filterType.includes(item.ticket_type)
        && (item.review_comment ? item.review_comment.includes(this.state.filterSearch) : this.state.filterSearch ? false : true)
        && (this.state.startDate ? moment(item.reviewed_at).isSameOrAfter(this.state.startDate) : true)
        && (this.state.endDate ? moment(item.reviewed_at).isSameOrBefore(this.state.endDate) : true);
    });

    let filteredRating = filteredFeed.length > 0 ? Math.round(filteredFeed.map(item => item.review_rating).reduce((total, rating) => total + rating) / filteredFeed.length * 100) / 100 : 1;

    var filteredTagsObject = filteredFeed.map(item => item.tags).reduce((accum, item) => {
      return accum.concat(item);
    }, []).reduce((accum, item) => {
      if (!accum[item.id]) { accum[item.id] = item; accum[item.id].count = 1; }
      else { accum[item.id].count += 1; }
      return accum;
    }, {});
    var filteredTags = Object.keys(filteredTagsObject).map(item => filteredTagsObject[item]);

    return (
      <div className="bg-light">
        <div className="container pt-2 pb-5">
          <div className="row mt-4">
            <div className="d-none d-md-block col-12 col-md-3">
              <Filters
                search={true}
                onFilterChange={this.onFilterChange}
                onSearchChange={this.onSearchChange}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDateChange={this.onDateChange}
                onFilterClear={this.onFilterClear}
                users={this.state.users}
                types={this.state.types}
                filterSearch={this.state.filterSearch}
                user={this.props.user}
              />
            </div>
            <div className="col-12 col-md-9">
              <Summary
                responseRate={this.state.responseRate}
                averageRatings={filteredRating} // CHANGE NAMES
                filteredTags={filteredTags}
              />
              <Feed
                filteredFeed={filteredFeed}
                loading={this.state.loading}
                error={this.state.error}
                refreshFeed={this.refreshFeed}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default Main;
