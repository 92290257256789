import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FilterDates.css';

class Filters extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div className="card border-0 shadow-sm">
        <div className="card-body">
          <button className="btn btn-sm btn-link float-right" onClick={this.props.onFilterClear}>Reset all filters</button>
          <h4 className="mb-4">Filters</h4>
          {this.props.search &&
            <input className="form-control" placeholder="&#x1F50D;&#xFE0E; Search comments" value={this.props.filterSearch} onChange={this.props.onSearchChange}></input>
          }
          <h6 className="mt-4">By date <span className="small text-secondary">Limited to last 30 days</span></h6>
          <DateRangePicker
            startDate={this.props.startDate} // momentPropTypes.momentObj or null
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired
            endDate={this.props.endDate} // momentPropTypes.momentObj or null
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired
            onDatesChange={this.props.onDateChange} // PropTypes.func.isRequired
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null
            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
            showClearDates={true}
            small={true}
            block={true}
            displayFormat="DD/MM/YY"
            startDatePlaceholderText="Start date"
            endDatePlaceholderText="End date"
            isOutsideRange={() => false}
          />
          <h6 className="mt-4">By rating</h6>
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="ratingCheck5" data-filter="5" data-category="Rating" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="ratingCheck5">
              <div className="text-success">
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
              </div>
            </label>
          </div>
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="ratingCheck4" data-filter="4" data-category="Rating" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="ratingCheck4">
              <div className="text-success">
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
              </div>
            </label>
          </div>
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="ratingCheck3" data-filter="3" data-category="Rating" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="ratingCheck3">
              <div className="text-danger">
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
              </div>
            </label>
          </div>
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="ratingCheck2" data-filter="2" data-category="Rating" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="ratingCheck2">
              <div className="text-danger">
                <FontAwesomeIcon icon="star" className="mr-1" />
                <FontAwesomeIcon icon="star" className="mr-1" />
              </div>
            </label>
          </div>
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="ratingCheck1" data-filter="1" data-category="Rating" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="ratingCheck1">
              <div className="text-danger">
                <FontAwesomeIcon icon="star" className="mr-1" />
              </div>
            </label>
          </div>
          <h6 className="mt-4">By channel</h6>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id="channelCheck1" data-filter="email" data-category="Channel" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="channelCheck1"><FontAwesomeIcon icon="envelope" className="text-secondary mr-2" /></label>
          </div>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id="channelCheck2" data-filter="phone" data-category="Channel" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="channelCheck2"><FontAwesomeIcon icon="phone" className="text-secondary mr-2" /></label>
          </div>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id="channelCheck3" data-filter="chat" data-category="Channel" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="channelCheck3"><FontAwesomeIcon icon="comments" className="text-secondary mr-2" /></label>
          </div>
          <h6 className="mt-4">By resolution</h6>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id="resolutionCheck1" data-filter="true" data-category="Resolution" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="resolutionCheck1">Resolved</label>
          </div>
          <div className="custom-control custom-checkbox custom-control-inline mr-0 pr-0">
            <input type="checkbox" className="custom-control-input" id="resolutionCheck2" data-filter="false" data-category="Resolution" onChange={this.props.onFilterChange} />
            <label className="custom-control-label" htmlFor="resolutionCheck2">Unresolved</label>
          </div>
          <h6 className="mt-4">By agent</h6>
          {this.props.users
          .sort((a,b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0))
          .map(user => {
            return <div className="custom-control custom-checkbox" key={user.id}>
              <input type="checkbox" className="custom-control-input" id={`agent-${user.email}`} data-filter={user.email} data-category="Agent" onChange={this.props.onFilterChange} />
              <label className="custom-control-label" htmlFor={`agent-${user.email}`}>
                {user.first_name} {user.last_name}
                {user.email === this.props.user.email &&
                  <FontAwesomeIcon icon="user" className="text-secondary ml-1" />
                }
              </label>
            </div>
          })}
          <h6 className="mt-4">By type</h6>
          {this.props.types && this.props.types
          .sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
          .map((type, index) => {
            return <div className="custom-control custom-checkbox" key={index}>
              <input type="checkbox" className="custom-control-input" id={`type-${type}`} data-filter={type} data-category="Type" onChange={this.props.onFilterChange} />
              <label className="custom-control-label" htmlFor={`type-${type}`}>
                {type}
              </label>
            </div>
          })}

        </div>
      </div>
    );
  }

}

export default Filters;