import React, { Component } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlipMove from 'react-flip-move';

class Summary extends Component {
  render() {
    return (
      <div className="d-none d-md-block card border-0 shadow-sm">
        <div className="card-body">
          <h4>Summary</h4>
          <div className="row text-center mt-4">
            <div className="col-12 col-md-3">
              <h6>Response rate</h6>
              <div className="mt-3" style={{ height: "120px" }}>
                <ReactSpeedometer
                  value={this.props.responseRate}
                  minValue={0}
                  maxValue={1}
                  segments={4}
                  fluidWidth={true}
                  needleColor="#343a40"
                  startColor="#ffc107"
                  endColor="#ffc107"
                  ringWidth={10}
                  textColor="#343a40"
                  valueFormat="~%"
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <h6>Average rating</h6>
              <div className="mt-3" style={{ height: "120px" }}>
                <ReactSpeedometer
                  value={this.props.averageRatings}
                  minValue={1}
                  maxValue={5}
                  segments={4}
                  fluidWidth={true}
                  needleColor="#343a40"
                  startColor="#ffc107"
                  endColor="#ffc107"
                  ringWidth={10}
                  textColor="#343a40"
                // currentValueText={"${value} stars"}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <h6>Areas of high performance</h6>
              <div>
                <FlipMove
                  staggerDurationBy='30'
                  duration={200}
                >
                  {this.props.filteredTags.filter(tag => tag.type === 'positive' && tag.count > 0).sort((a, b) => b.count - a.count).slice(0, 4).map(tag => {
                    return <span key={tag.id} className="badge badge-success text-left m-1 w-100">
                      <FontAwesomeIcon icon={tag.icon} className="mr-1" />
                      {tag.name}
                      <span className="badge badge-pill badge-light float-right">{tag.count}</span>
                    </span>
                  })}
                  {this.props.filteredTags.filter(tag => tag.type === 'positive' && tag.count > 0).length === 0 &&
                  <span className="badge badge-light text-center m-1 w-100">
                    None identified
                  </span>
                  }
                  </FlipMove>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <h6>Areas of low performance</h6>
              <div>
              <FlipMove
                  staggerDurationBy='30'
                  duration={200}
                >
                  {this.props.filteredTags.filter(tag => tag.type === 'negative' && tag.count > 0).sort((a, b) => b.count - a.count).slice(0, 4).map(tag => {
                    return <span key={tag.id} className="badge badge-danger text-left m-1 w-100">
                      <FontAwesomeIcon icon={tag.icon} className="mr-1" />
                      {tag.name}
                      <span className="badge badge-pill badge-light float-right">{tag.count}</span>
                    </span>
                  })}
                  {this.props.filteredTags.filter(tag => tag.type === 'negative' && tag.count > 0).length === 0 &&
                  <span className="badge badge-light text-center m-1 w-100">
                    None identified
                  </span>
                  }
                </FlipMove>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Summary;