import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar, faHandshake, faMicrophone, faLightbulb, faFastForward, faDumbbell, faSmile, faEnvelope, faPhone, faComments, faInfoCircle, faUser, faEdit, faTrash, faUserPlus, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { faSync, faFileCsv, faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSlack } from '@fortawesome/free-brands-svg-icons';

import ReviewRequest from './components/ReviewRequest';
import Dashboard from './components/Dashboard';
import Leaderboard from './components/Leaderboard';
import Login from './components/Login';

library.add(faStar, faHandshake, faMicrophone, faLightbulb, faFastForward, faDumbbell, faSmile, faEnvelope, faPhone, faComments, faCheck, faInfoCircle, faUser, faEdit, faTrash, faUserPlus, faSpinner, faSync, faFileCsv, faTimes, faSlack)

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: true,
      user: {
        id: '',
        company: {
          logo_url: ''
        }
      },
      error: null
    };
    this.setLoggedIn = this.setLoggedIn.bind(this);
  };

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    if (!accessString) { this.setState({ loggedIn: false }); }
    axios.get('/auth/user', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => { this.setState({ user: response.data.user }); })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('JWT');
          this.setState({ loggedIn: false });
        } else {
          this.setState({ error: error.response.data.message });
        }
      });
  };

  setLoggedIn() {
    this.setState({ loggedIn: true });
  }

  render() {
    return (
      <Router>
        <div>
          <Route exact path='/' render={() => (
            <Redirect to='/dashboard' />
          )} />
          <Route path='/dashboard' render={(props) => <Dashboard {...props} user={this.state.user} loggedIn={this.state.loggedIn} />} />
          <Route path='/leaderboard' render={(props) => <Leaderboard {...props} user={this.state.user} />} />
          <Route path='/review/:id' component={ReviewRequest} />
          <Route path='/login' render={(props) => <Login {...props} updateUser={this.updateUser} setLoggedIn={this.setLoggedIn} />} />
          {this.state.error &&
            <div className="alert alert-danger fixed-bottom m-3 ml-auto" style={{ width: '300px' }} role="alert"><strong>An error occured: </strong>{this.state.error}</div>
          }
        </div>
      </Router>
    );
  };
};

export default App;
