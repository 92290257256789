import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { CSSTransitionGroup } from 'react-transition-group';

class Feed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showExtraInfo: false
    };
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter(event) {
    this.setState({ showExtraInfo: true });
  }

  onMouseLeave(event) {
    this.setState({ showExtraInfo: false });
  }

  render() {

    const stars = [];
    let colorClass;
    switch (this.props.review_rating) {
      case 1: colorClass = 'text-danger'; break;
      case 2: colorClass = 'text-danger'; break;
      case 3: colorClass = 'text-danger'; break;
      case 4: colorClass = 'text-success'; break;
      case 5: colorClass = 'text-success'; break;
      default: colorClass = 'text=primary';
    }
    for (let i = 0; i < this.props.review_rating; i++) {
      stars.push(<FontAwesomeIcon key={i} icon="star" className="mr-1" />);
    }

    // required to prevent .map errors on null values
    // let tags = [];
    // if (this.props.tags) {
    //   tags = ;
    //   });
    // }

    const capitalize = (string) => {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
    };

    return (
      <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <div className="card mt-2 border-0 shadow-sm">
          <div className="card-body p-3">
            <img src={this.props.image_url} alt="agent_email" className="rounded-circle float-left mr-3" style={{ objectFit: 'cover', width: '50px', height: '50px' }} />
            <div className="d-none d-md-block">
              {this.props.tags.map(tag => {
                return <div key={tag.id} className={`badge ${tag.type === 'positive' ? 'badge-success' : 'badge-danger'} mx-1 float-right`}>{tag.name}</div>
              })}
            </div>
            <h6>
              {this.props.first_name} {this.props.last_name}
            </h6>
            <div className={colorClass}>
              {stars}
            </div>
            {this.props.review_comment &&
              <div className="mt-3">{this.props.review_comment}</div>
            }
          </div>
        </div>
        <div className="small text-muted mt-1 mx-3" style={{ height: '16px' }}>
          <CSSTransitionGroup
            transitionName="extra-info"
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}>
            {this.state.showExtraInfo &&
              <span>
                <span className="mr-1">{moment(this.props.created_at).fromNow()}</span>
                <span className="mr-1">·</span>
                <span className="mr-1">{capitalize(this.props.channel)}</span>
                <span className="mr-1">·</span>
                <span className="mr-1">{this.props.customer_name}</span>
                <span className="mr-1">·</span>
                <span className="mr-1">{this.props.customer_email}</span>
                <span className="mr-1">·</span>
                <span className="mr-1">{this.props.ticket_type}</span>
                <span className="mr-1">·</span>
                <span className="mr-1"><a href={this.props.ticket_url} target="_blank" rel="noopener noreferrer">Ticket</a></span>
                <span className="mr-1">·</span>
                <span className="mr-1"><a href={`/review/${this.props.id}`} target="_blank" rel="noopener noreferrer">Review</a></span>
              </span>
            }
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

export default Feed;