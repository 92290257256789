import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class TrendsCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      labels: ['2018-01-01', '2018-01-02', '2018-01-04', '2018-01-05'],
      data: [1, 2, 3, 2]
    };
    this.getChart = this.getChart.bind(this);
  }

  getChart() {
    return {
      labels: this.props.labels,
      datasets: [{
        label: 'Average star rating',
        data: this.props.data,
        borderColor: '#007bff',
        backgroundColor: 'transparent',
        pointBackgroundColor: '#007bff',
        pointRadius: 4
      }],
    }
  }

  render() {
    return (
      <div className="d-none d-md-block card border-0 shadow-sm">
        <div className="card-body">
          <h4 className="mb-3">Trends</h4>
          <Line
            data={this.getChart}
            options={{
              legend: {
                display: false
              },
              scales: {
                xAxes: [{
                  gridLines: {
                    display: false
                  }
                }],
                yAxes: [{
                  gridLines: {
                    display: true
                  }
                }]
              }
            }}
          />
        </div>
      </div>
    );
  }

}

export default TrendsCharts;