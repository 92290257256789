import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Log extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interactions: [],
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/interactions?limit=100', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => this.setState({
        loading: false,
        interactions: response.data
      }))
      .catch(error => {
        this.setState({
          loading: false,
          error: error.message
        });
      })
  }

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-4 pb-5">
          <CSVLink data={this.state.interactions} className="btn btn-outline-primary float-right mt-2"><FontAwesomeIcon icon={['fal', 'file-csv']} className="mr-1" />Download CSV</CSVLink>
          <h1 className="font-weight-light mb-4">Log</h1>
          <p>Most recent 100 interactions</p>
          <form className="card border-0 shadow-sm">
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{ borderBottomWidth: '1px' }}>Customer</th>
                    <th scope="col" style={{ borderBottomWidth: '1px' }}>Channel</th>
                    <th scope="col" style={{ borderBottomWidth: '1px' }}>Agent</th>
                    <th scope="col" style={{ borderBottomWidth: '1px' }}>Category</th>
                    <th scope="col" style={{ borderBottomWidth: '1px' }}>Ticket closed</th>
                    <th scope="col" style={{ borderBottomWidth: '1px' }}>Emailed?</th>
                    <th scope="col" style={{ borderBottomWidth: '1px' }}>Rated?</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.interactions.map(interaction => {
                    return <tr key={interaction.id}>
                      <td>{interaction.customer_name}</td>
                      <td>{interaction.channel.charAt(0).toUpperCase() + interaction.channel.slice(1)}</td>
                      <td>{interaction.agent_email}</td>
                      <td>{interaction.ticket_type}</td>
                      <td>{moment(interaction.created_at).fromNow()}</td>
                      <td>{interaction.emailed_at ? <FontAwesomeIcon icon={['fal', 'check']} className="text-success" /> : <FontAwesomeIcon icon={['fal', 'times']} className="text-danger" />}</td>
                      <td>{interaction.reviewed_at ? <FontAwesomeIcon icon={['fal', 'check']} className="text-success" /> : <FontAwesomeIcon icon={['fal', 'times']} className="text-danger" />}</td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    );
  }

}

export default Log;