import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import Filters from './Filters';
import TrendsCharts from './TrendsCharts';

let initialState = {
  users: [],
  filterRating: [null, 1, 2, 3, 4, 5],
  filterChannel: [null, 'email', 'phone', 'chat'],
  filterResolution: [null, 'true', 'false'],
  filterAgent: [null],
  startDate: null,
  endDate: null,
  data: [],
  labels: [],
  loading: true,
  error: null
};

class Trends extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.getData = this.getData.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterClear = this.onFilterClear.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidMount() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/agents', { headers: { Authorization: `JWT ${accessString}` } })
      .then(response => {
        initialState.filterAgent = response.data.map(user => user.email);
        this.setState({
          users: response.data,
          filterAgent: response.data.map(user => user.email)
        },
          () => this.getData());
      })
      .catch(error => { console.log(error); })

  };

  getData() {
    let accessString = localStorage.getItem('JWT');
    axios.get('/api/interactions/trend', {
      headers: { Authorization: `JWT ${accessString}` },
      params: {
        filterRating: this.state.filterRating,
        filterChannel: this.state.filterChannel,
        filterResolution: this.state.filterResolution,
        filterAgent: this.state.filterAgent,
        startDate: this.state.startDate ? moment(this.state.startDate).format() : null,
        endDate: this.state.endDate ? moment(this.state.endDate).format() : null,
      }
    })
      .then(response => {
        this.setState({
          data: response.data.map(item => item.value),
          labels: response.data.map(item => moment(item.date).format('D MMM'))
        })
      })
      .catch(error => { console.log(error.message); })
  }

  onFilterChange(event) {
    let clickedCategory = event.target.dataset.category;
    let clickedValue = clickedCategory === 'Rating' ? parseInt(event.target.dataset.filter) : event.target.dataset.filter;
    let updatedFilterList = this.state['filter' + clickedCategory].length === initialState['filter' + clickedCategory].length ? [] : this.state['filter' + clickedCategory];
    if (event.target.checked) {
      updatedFilterList.push(clickedValue);
    } else {
      updatedFilterList = updatedFilterList.length === 1 ? initialState['filter' + clickedCategory] : updatedFilterList.filter(value => value !== clickedValue);
    }
    this.setState(
      { ['filter' + clickedCategory]: updatedFilterList },
      () => this.getData()
    );
  };

  onFilterClear(event) {
    event.preventDefault();
    document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
    this.setState({
      filterRating: initialState.filterRating,
      filterChannel: initialState.filterChannel,
      filterResolution: initialState.filterResolution,
      filterAgent: initialState.filterAgent,
      filterSearch: initialState.filterSearch,
      startDate: null,
      endDate: null
    }, () => this.getData());
  };

  onDateChange({ startDate, endDate }) {
    this.setState(
      { startDate, endDate },
      () => this.getData()
    )
  };

  getChart() {
    return {
      labels: this.state.labels,
      datasets: [{
        label: 'Count of events',
        data: this.state.data,
        backgroundColor: '#007bff',
      }],
    }
  }

  render() {
    return (
      <div className="bg-light">
        <div className="container pt-2 pb-5">
          {/* <h1 className="font-weight-light mb-4">Trends</h1> */}
          <div className="row mt-4">
            <div className="d-none d-md-block col-12 col-md-3 bg-light">
              <Filters
                search={false}
                onFilterChange={this.onFilterChange}
                onSearchChange={this.onSearchChange}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDateChange={this.onDateChange}
                onFilterClear={this.onFilterClear}
                users={this.state.users}
                filterSearch={this.state.filterSearch}
                user={this.props.user}
              />
            </div>
            <div className="col-12 col-md-9">
              <TrendsCharts
                data={this.state.data}
                labels={this.state.labels}
              />
            </div>
          </div>
        </div>
      </div >
    );
  }

}

export default Trends;