import React, { Component } from 'react';
import FlipMove from 'react-flip-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FeedItem from './FeedItem';
import FeedLoader from './FeedLoader';

class Feed extends Component {

  render() {

    return (
      <div className="p-2 mt-4">
        <FontAwesomeIcon icon={['fal', 'sync']} spin={this.props.loading} className="float-right" onClick={this.props.refreshFeed} />
        <h4>Feed</h4>
        <FlipMove
          staggerDurationBy='30'
          duration={200}
        >
          {this.props.filteredFeed.length ?
          this.props.filteredFeed
            .map(item => {
              return (
                <FeedItem key={item.id} {...item.agent} {...item.tags} {...item} />
              )
            })
          : this.props.loading ?
            <FeedLoader />
          : this.props.error ?
            <div className="alert alert-danger" role="alert">{this.props.error}</div>
          :
          <div className="alert alert-warning" role="alert">
            <strong>Oh snap!</strong> No interactions met the criteria.
          </div>}
        </FlipMove>
      </div>
    );
  }

}

export default Feed;